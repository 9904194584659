// animation.scss
@use "variables" as *;
@use "keyframes";

.slideUp {
	opacity: 0;
	animation: slide-up 1s;
	animation-delay: 0.2s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
	&:nth-of-type(2) {
		animation-delay: 0.6s;
	}
	&:nth-of-type(3) {
		animation-delay: 0.9s;
	}
	&:nth-of-type(4) {
		animation-delay: 0.9s;
	}
}
.slideDelay {
    animation-delay: 0.4s;
}
.fadeIn {
	opacity: 0;
	animation: fade-in 0.5s;
	animation-delay: 0.5s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}
.minusTop {
	opacity: 0;
	animation: minus-top 0.5s;
	animation-delay: 0.5s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}