@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        background: $bg-dark;
        color: $base-white;

        .logo {
            width: 100%;
            max-width: 185px;
        }

        .impellam-logo {
            max-width: 160px;
        }

        .col-lg-2 {

            @media(max-width: 1250px) {
                width: 17.666667%;
            }
        }

        .col-md-6 {

            @media(max-width: 999px) {
                width: 50%;
            }

            @media(max-width: 767px) {
                width: 100%;
            }
        }

        p {
            font-weight: 600;
        }

        .navigations {
            a {
                font-size: 0.875rem;
                font-weight: 500;
            }
        }

        .sourceflow {
            svg {
                width: 120px;
                height: auto;

                // path {
                //     fill: $base-black;
                // }
            }
        }

        .impellem-group {
            white-space: nowrap;

            svg {
                width: 150px;
                height: auto;
            }
        }

        a {
            @include trans;

            &:hover,
            &:focus,
            &:active {
                color: $base-white;
                opacity: 0.5;
            }
        }

        .email {
            font-size: 0.75rem !important;
        } 
    }
}