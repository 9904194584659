@use "variables" as *;
@use "mixins" as mix;

* {
  	box-sizing: border-box;
}
html {
  	scroll-behavior: smooth;
}
body {
  	@include mix.scrollbars(0.4rem, 0, $primary-color, $bg-light);
}
html,
body {
	padding: 0;
	margin: 0;
}
a {
    color: inherit;
    text-decoration: none;
}
.sr-only {
    position: absolute;
    pointer-events: none;
    visibility: hidden;
}
a.cursor-pointer {
	text-decoration: none;
	cursor: pointer!important;
	&:hover {
		cursor: pointer!important;
	}
}